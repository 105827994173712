
import { defineComponent, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import mobleView from './moble-view/homePage.vue';

export default defineComponent({
  metaInfo: {
    title: '恋友', // set a title
    meta: [
      // set meta
      {
        name: 'keyWords',
        content:
          '恋友,恋友官网,语音聊天,语音连麦,游戏陪伴,派对游戏,电台FM,速配交友,情感陪伴,情绪治愈,扩列,脱单盲盒,王者荣耀开黑,和平精英开黑,元宇宙社交'
      },
      {
        name: 'description',
        content:
          '拥有语音聊天、多人派对、互动娱乐游戏，脱单盲盒、心动交友、元宇宙交友等潮流功能。电竞新选择，社交心体验。'
      }
    ]
  },
  name: 'App',
  components: { mobleView },
  setup() {
    //对应变色的导航
    let activeA: any = ref('1');
    const $router = useRouter();
    const $route = useRoute();

    //点击导航栏变色
    const activeNav = (index: string) => {
      activeA.value = index;
      sessionStorage.setItem('isSelect', activeA.value);
      if (activeA.value === '1') {
        $router.push({ path: 'home' });
      } else if (activeA.value === '2') {
        $router.push({ path: 'about' });
      } else if (activeA.value === '3') {
        $router.push({ path: 'privacy' });
      }
    };
    watch($route, () => {
      if ($route.path === '/home' || $route.path === '/') {
        activeA.value = '1';
        sessionStorage.setItem('isSelect', activeA.value);
      } else if ($route.path === '/about') {
        activeA.value = '2';
        sessionStorage.setItem('isSelect', activeA.value);
      } else if ($route.path === '/privacy') {
        activeA.value = '3';
        sessionStorage.setItem('isSelect', activeA.value);
      }
    });

    // const resize = () => {
    //   var htmlEle = document.documentElement;
    //   var htmlWidth = window.innerWidth;
    //   htmlEle.style.fontSize = 100 * (htmlWidth / 750) + 'px';
    // };

    onMounted(() => {
      console.log($route.fullPath);
      activeA.value = '1';
      if (sessionStorage.getItem('isSelect')) {
        activeA.value = sessionStorage.getItem('isSelect');
      }
    });
    return {
      activeA,
      activeNav
    };
  }
});
